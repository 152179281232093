import React from "react";

import Layouts from "../../components/Layouts";
import Seo from "../../components/Seo";
import { PageHeader } from "../../components/Atomics/PageHeader";
import { Cta } from "../../components/Cta";
import { ImagesGallery } from "../../components/Screens/Careers/ImagesGallery";
import { CareerValues } from "../../components/Screens/Careers/ValuesCards";
import { CareersPreview } from "../../components/Screens/Careers/CareersPreview";
import { graphql } from "gatsby";

const CareersPageArabic = ({ data }) => {
	const careers = data.allWpCareer.edges || [];
	const page = data.page;
	return (
		<>
			<Seo title="وظائف" />
			<Layouts lang={"ar"} dir="rtl">
				<PageHeader
					miniHeader="وظائف"
					header="ثقافة العمل في كاشات"
					text="كاشات أول شركة تكنولوجيا مالية للهاتف، وعندها طموح كبير إنها تحل المشاكل الصعبة في المنطقة، وتقدم الحلول المالية ليها بحيث تكون من الأنظمة المالية اللي بتتميز بالتقدم والشمولية."
				/>
				<ImagesGallery
					imagesGallery={page.imagesSliderCareersAr.sliderCareersAr}
				/>
				<CareerValues
					valuesCards={page.values.valuesCareersAr.values}
					miniHeader={page.values.valuesCareersAr.title}
					header={page.values.valuesCareersAr.text}
				/>
				<CareersPreview
					header="الوظائف المتاحة"
					miniHeader={
						<>
							لو حابب تنضم لفريق كاشات، ابعت السيرة الذاتية على
							careers@kashat.com.eg
						</>
					}
					lang="ar"
					// jobsCards={careers}
				/>
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};
export default CareersPageArabic;
export const CareersArabicQuery = graphql`
	query CareersArabic {
		allWpCareer(
			filter: { language: { code: { eq: AR }, slug: { ne: null } } }
		) {
			edges {
				node {
					id
					language {
						code
						locale
					}
					date
					title
					slug
					careerPage {
						shortDescription
						location
					}
				}
			}
		}
		page: wpPage(databaseId: { eq: 276 }) {
			title
			imagesSliderCareersAr {
				sliderCareersAr {
					imageCareersSlider {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000, placeholder: BLURRED)
							}
						}
					}
					altCareersImageSlider
				}
			}
			values: careersValuesAr {
				valuesCareersAr {
					title
					text
					values {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 100, placeholder: BLURRED)
								}
							}
						}
						title
						text
					}
				}
			}
		}
	}
`;
